<template>
  <div>
    <app-header />

    <section class="error-wrap">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-10">
            <div class="text-center">
              <img src="/assets/img/404.png" class="img-fluid" alt="" />
              <p>This page could not be found.</p>
              <a
                class="btn btn-theme"
                :href="$router.resolve({ name: 'redirect' }).href"
                >Back To Home</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "./public/components/AppHeader.vue";
import AppFooter from "./public/components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
